const initAdvancedtopmenu = () => {
    
    const menu_mobile_toggle = document.querySelectorAll(".menu-mobile-toggle");
    const submenu_mobile_toggle = document.querySelectorAll(".adtm-toggle-submenu-mobile");
    const header_middle = document.querySelector(".header-middle");    
    const submenu_column_mobile_toggle = document.querySelectorAll(".adtm-toggle-submenu-column-mobile");
    
    function menuMobileToggle(element : any): void {           
        element.classList.toggle("show-menu-mobile");
    }

    function submenuMobileToggle(element : any): void {           
        element.classList.toggle("adtm_sub-active");
    }

    function submenuColumnMobileToggle(element : any): void {           
        element.classList.toggle("adtm_elements-active");
    }
    
    menu_mobile_toggle.forEach((menu) => {
        menu.addEventListener('click', (e) => {
            menuMobileToggle(header_middle); 
        });   
    });
    
    submenu_mobile_toggle.forEach((menu) => {
        menu.addEventListener('click', (e) => {
            let adtm_sub = document.querySelector(".adtm_sub_"+menu.getAttribute('data-menu_id'));
            submenuMobileToggle(adtm_sub);
        });   
    });

    submenu_column_mobile_toggle.forEach((menu) => {
        menu.addEventListener('click', (e) => {
            let adtm_elements = document.querySelector(".adtm_elements_"+menu.getAttribute('data-id_column'));
            let button_close = document.querySelector(".adtm-toggle-submenu-column-mobile-"+menu.getAttribute('data-id_column'));
            if(adtm_elements){
                adtm_elements.classList.toggle("adtm_elements-active");
            }if(button_close){
                button_close.classList.toggle("close");
            }
        });   
    });
    
};

export default initAdvancedtopmenu;