/**
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import themeSelectors from './constants/selectors-map';
import EVENTS from './constants/events-map';
import initEmitter from './prestashop';
import initResponsiveToggler from './responsive-toggler';
import initQuickview from './quickview';
import initCart from './pages/cart';
import initCheckout from './pages/checkout';
import initCustomer from './pages/customer';
import initSwiperProductGallery from './product';
//import initMobileMenu from './mobile-menu';
import initAdvancedtopmenu from './modules/pm_advancedtopmenu';
import initSearchbar from './modules/ps_searchbar';
import initLanguageSelector from './modules/ps_languageselector';
import initCurrencySelector from './modules/ps_currencyselector';
import initVisiblePassword from './visible-password';
import initHeaderAnimations from './components/header';
import useToast from './components/useToast';
import initErrorHandler from './errors';
import useAlert from './components/useAlert';
import usePasswordPolicy from './components/usePasswordPolicy';
import useProgressRing from './components/useProgressRing';
import useQuantityInput from './components/useQuantityInput';
import './modules/blockcart';
import './modules/facetedsearch';
import initMessageheaderslider from './modules/divioseo_customtextmessageheader';
import initHpAnimations from './modules/divioseo_hpanimation';
import initImageslistAnimations from './modules/divioseo_imageslist';
import initVideosslider from './modules/divioseo_videosslider';
import initSpecialproductsslider from './modules/divioseo_specialproducts';
import initCategoriesproducts from './modules/divioseo_products';
import initTestimonialsslider from './modules/divioseo_testimony';
//import initDesktopMenu from './modules/ps_mainmenu';
import initComments from './modules/divioseo_comments';
import initTutorials from './modules/divioseo_tutorials';
import initPhilosophydetails from './modules/divioseo_philosophydetails';
import initFormValidation from './form-validation';
import initBackTop from './services/backtop';

initEmitter();

$(() => {
  const {prestashop, Theme: {events}} = window;

  initHeaderAnimations();
  initSwiperProductGallery();
  initQuickview();
  initCheckout();
  initCustomer();
  initResponsiveToggler();
  initCart();
  useQuantityInput();
  initAdvancedtopmenu();
  initSearchbar();
  initLanguageSelector();
  initCurrencySelector();
  //initMobileMenu();
  initVisiblePassword();
  //initDesktopMenu();
  initFormValidation();
  initErrorHandler();
  usePasswordPolicy('.field-password-policy');
  initMessageheaderslider();
  initHpAnimations();
  initImageslistAnimations();
  initVideosslider();
  initTestimonialsslider();
  initSpecialproductsslider();
  initCategoriesproducts();
  initBackTop();
  initComments();
  initTutorials();
  initPhilosophydetails();

  prestashop.on(events.responsiveUpdate, () => {
    initHeaderAnimations();
    initAdvancedtopmenu();
    initSearchbar();
    initLanguageSelector();
    initCurrencySelector();
    //initDesktopMenu();
    initMessageheaderslider();
    initHpAnimations();
    initImageslistAnimations();
    initVideosslider();
    initTestimonialsslider();
    initSpecialproductsslider();
    initCategoriesproducts();
    initBackTop();
    initComments();
    initTutorials();
    initPhilosophydetails();
  });
});

export const components = {
  useToast,
  useAlert,
  useProgressRing,
  useQuantityInput,
};

export const selectors = themeSelectors;

export const events = EVENTS;

export default {
  initHeaderAnimations,
  initSwiperProductGallery,
  initQuickview,
  initCheckout,
  initResponsiveToggler,
  initCart,
  useQuantityInput,
  initAdvancedtopmenu,
  initSearchbar,
  initLanguageSelector,
  initCurrencySelector,
  //initMobileMenu,
  initVisiblePassword,
  //initDesktopMenu,
  initMessageheaderslider,
  initHpAnimations,
  initImageslistAnimations,
  initVideosslider,
  initTestimonialsslider,
  initSpecialproductsslider,
  initCategoriesproducts,
  initComments,
  initTutorials,
  initPhilosophydetails,
};
