import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const initCategoriesproducts = () => {

    const categories = document.querySelectorAll(".categoriesproducts-category-js");
    const products = document.querySelectorAll(".categoriesproducts-products-js");
    const products_lightbox = document.querySelector(".products-lightbox-js");
    const products_lightbox_close = document.querySelector(".categoriesproducts-products-close-js");    
    const blocFilter = document.querySelector('.bloc-categories-filter');
    
    let swipers:any[] = [];

    
    function addSvgColor(element : any): void {           
        if (window.innerWidth > 1199) {   
            if(element.classList.contains('active')){
                let color = element.querySelector('svg').getAttribute('data-color');
                element.querySelector('svg>g>*').style.fill = color;
            }else{
                element.querySelector('svg>g>*').style.fill = '#fff';
            }
        }else{
            let color = element.querySelector('svg').getAttribute('data-color');
            element.querySelector('svg>g>*').style.fill = color;
        }
    }

    function removeSvgColor(element : any): void {        
        if (window.innerWidth > 1199) {
            element.querySelector('svg>g>*').style.fill = '#fff';
        }
    } 

    function selectActiveProducts(element : any): void {       
        let category_id = element.getAttribute('data-category'); 
        products.forEach((element) => {
            if(element.classList.contains("products-"+category_id)){
                element.classList.add('active');
            }else{                
                element.classList.remove('active');
            }
        });
    }
    
    function openLightboxProducts (element : any): void {       
        element.classList.add('active'); 
    }

    function closeLightboxProducts (element : any): void {       
        element.classList.remove('active'); 
    }             
    
    function fixBlocFilter(elementFilter : any): void {      
            
        let c_categories = document.querySelector(".categories-products .categories");
        let width = 0;
        if(c_categories && c_categories !== null ){
            width = c_categories.getBoundingClientRect().width;
        }
        
        const elementResults = document.querySelector('.bloc-categories-results');
        if(elementFilter && elementResults && elementFilter !== null && elementResults !== null ){            
            if (window.innerWidth > 1199) {
                const elementResultsTop = elementResults.getBoundingClientRect().top;
                const elementResultsHeight = elementResults.getBoundingClientRect().height;
                const elementResultsBottom = elementResults.getBoundingClientRect().bottom;
                const blocFilterHeight = elementFilter.getBoundingClientRect().height;
                            
                if (elementResultsTop <= 130 && elementResultsBottom > 130 + blocFilterHeight) {
                    elementFilter.style.position = "fixed";
                    elementFilter.style.top = "130px";
                    elementFilter.style.width = width+'px';   
                    elementFilter.style.bottom = "initial";                 
                } else if(elementResultsBottom + (elementResultsBottom - blocFilterHeight - 130) <= window.innerHeight) {
                    elementFilter.style.position = "absolute";
                    elementFilter.style.top = "initial";
                    elementFilter.style.bottom = 0;  
                    elementFilter.style.width = width+'px';    
                } else {
                    elementFilter.style.position = "relative";
                    elementFilter.style.top = "initial";
                }
            }else {
                elementFilter.style.position = "relative";
                elementFilter.style.top = "initial";
            }
        }
    }

    function initSwiper(element : any): void {       
        let category_id = element.getAttribute('data-category'); 
        if (window.innerWidth <= 1199) {
            if ( swipers[category_id] === undefined ) {
                swipers[category_id] = new Swiper('.swiper-categoriesproducts-'+category_id, {
                    modules: [Navigation],
                    slidesPerView: 1,
                    spaceBetween: 0,

                    breakpoints: {
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                    },
                    navigation: {
                        nextEl: ".swiper-button-next-"+category_id,
                        prevEl: ".swiper-button-prev-"+category_id,
                    },
                });
            }
        }else{
            if ( swipers[category_id] !== undefined ) {
                swipers[category_id].destroy( true, true );
                swipers.splice(category_id, 1);
            }
        }
    }
    
    categories.forEach((category) => {
        addSvgColor(category);
        category.addEventListener('click', (e) => {
            categories.forEach((element) => {
                element.classList.remove('active');
                removeSvgColor(element);
            });
            category.classList.add('active');
            addSvgColor(category);

            let selected_category = document.querySelector(".categoriesproducts-category-js.active");
            selectActiveProducts(selected_category);   
                    
            if (window.innerWidth <= 1199) {                            
                openLightboxProducts(products_lightbox);
            }

        });

        initSwiper(category);     
    });

    
    window.addEventListener("resize", (e) => {
        categories.forEach((category) => {
            addSvgColor(category);
            initSwiper(category);    
        });
    });
    
    window.addEventListener("scroll", (e) => {  
        if (window.innerWidth > 1199) {
            fixBlocFilter(blocFilter);
        }
    });

    if(products_lightbox_close && products_lightbox_close !== null ){
        products_lightbox_close.addEventListener("click", (e) => {
            closeLightboxProducts(products_lightbox);
        });
    }
    
};

export default initCategoriesproducts;