/**
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

export const delay = 250;

export default {
  delay,
};
