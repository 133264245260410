const initComments = () => {
    
    const paginationNumbers = document.getElementById("pagination-numbers");
    const paginatedList = document.getElementById("reviewslist");
    
    if ( paginatedList && paginatedList !== undefined ) {
        const listItems = paginatedList.querySelectorAll("li");
        const nextButton = document.getElementById("next-button");
        const prevButton = document.getElementById("prev-button");
        
        const paginationLimit = 10;
        const pageCount = Math.ceil(listItems.length / paginationLimit);
        let currentPage = 1;
        
        const disableButton = (button: any) => {
            button.classList.add("disabled");
            button.setAttribute("disabled", true);
        };
        
        const enableButton = (button: any) => {
            button.classList.remove("disabled");
            button.removeAttribute("disabled");
        };
        
        const handlePageButtonsStatus = () => {
            if (currentPage === 1) {
                disableButton(prevButton);
            } else {
                enableButton(prevButton);
            }
            
            if (pageCount === currentPage) {
                disableButton(nextButton);
            } else {
                enableButton(nextButton);
            }
        };
        
        const handleActivePageNumber = () => {
            document.querySelectorAll(".pagination-number").forEach((button) => {
                button.classList.remove("active");
                const pageIndex = Number(button.getAttribute("page-index"));
                if (pageIndex == currentPage) {
                button.classList.add("active");
                }
            });
        };
        
        const appendPageNumber = (index: any) => {
            const pageNumber = document.createElement("button");
            pageNumber.className = "pagination-number";
            pageNumber.innerHTML = index;
            pageNumber.setAttribute("page-index", index);
            pageNumber.setAttribute("aria-label", "Page " + index);
            
            if ( paginationNumbers && paginationNumbers !== undefined ) {
                paginationNumbers.appendChild(pageNumber);
            }
        };
        
        const getPaginationNumbers = () => {
            for (let i = 1; i <= pageCount; i++) {
                appendPageNumber(i);
            }
        };
        
        const setCurrentPage = (pageNum: any) => {
            currentPage = pageNum;
            
            handleActivePageNumber();
            handlePageButtonsStatus();
            
            const prevRange = (pageNum - 1) * paginationLimit;
            const currRange = pageNum * paginationLimit;
            
            listItems.forEach((item, index) => {
                item.classList.add("hidden");
                if (index >= prevRange && index < currRange) {
                item.classList.remove("hidden");
                }
            });
        };
        
        window.addEventListener("load", () => {
            getPaginationNumbers();
            setCurrentPage(1);
            
            if ( prevButton && prevButton !== undefined ) {
                prevButton.addEventListener("click", () => {
                    setCurrentPage(currentPage - 1);
                });
            }
            
            if ( nextButton && nextButton !== undefined ) {
                nextButton.addEventListener("click", () => {
                    setCurrentPage(currentPage + 1);
                });
            }
            
            document.querySelectorAll(".pagination-number").forEach((button) => {
                const pageIndex = Number(button.getAttribute("page-index"));
            
                if (pageIndex) {
                button.addEventListener("click", () => {
                    setCurrentPage(pageIndex);
                });
                }
            });
        });
    }
    
};

export default initComments;