import Swiper from 'swiper';
import { EffectFade, Navigation, Pagination, Autoplay  } from 'swiper/modules';

const initMessageheaderslider = () => {
    new Swiper('.swiper-messageheader', {
        modules: [EffectFade, Navigation, Pagination, Autoplay ],
        loop: true,
        speed:2000,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        //spaceBetween: 30,
        //effect: "fade",
        //fadeEffect: { crossFade: true },
        centeredSlides: true,
    });
};

export default initMessageheaderslider;