const initBackTop = () => {
    
    const backtop = document.querySelector('.button-backtop');    
    
    function editBacktopPosition(button : any): void {    
        if (window.scrollY > 200) {
            button.classList.add("button-show");
        } else {
            button.classList.remove("button-show");
        }
    }

    window.addEventListener("scroll", (e) => {
        editBacktopPosition(backtop);
    });

    if ( backtop && backtop !== undefined ) {
        backtop.addEventListener("click", (e) => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        });
    }

};

export default initBackTop;