



const initPhilosophydetails = () => {
    const popup = document.querySelector("#star-element-popup");
    const open = document.querySelectorAll(".star-element-popup-open");
    const close = document.querySelector("#star-element-popup-close");
    const title = document.querySelector("#star-element-title");
    const description = document.querySelector("#star-element-description");
    
    

    // Function to request full screen
    function launchFullscreen(element : any) {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { // Firefox
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { // Chrome, Safari, and Opera
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // IE/Edge
            element.msRequestFullscreen();
        }
    }

    if ( open && open !== undefined ) {
        
        open.forEach((openpopup) => {
            openpopup.addEventListener('click', () => {
                const data_element:any = openpopup ? openpopup.getAttribute('data-element') : '';
                const data_title:any = openpopup ? openpopup.getAttribute('data-title') : '';
                const data_description:any = openpopup ? openpopup.getAttribute('data-description') : '';
    
                if ( title && title !== undefined ) {
                    title.textContent = data_title;
                }
                if ( description && description !== undefined ) {
                    description.textContent = data_description;
                }
                if ( popup && popup !== undefined ) {
                    popup.classList.toggle('actif');
                }

            });
        });
    }

    if ( close && close !== undefined ) {
        close.addEventListener('click', () => {                
            if ( title && title !== undefined ) {
                title.textContent = '';
            }
            if ( description && description !== undefined ) {
                description.textContent = '';
            }
            if ( popup && popup !== undefined ) {
                popup.classList.toggle('actif');
            }
        });
    }
};

export default initPhilosophydetails;