import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';

const initSwiperProductGallery = () => {

    let swiper:any;

    function initSwiper(): void {       
        if (window.innerWidth <= 1199) {
          if ( swiper === undefined ) {
            swiper = new Swiper('.swiper-product-gallery', {
                autoHeight: true,
                modules: [Pagination],
                pagination: {
                    el: ".swiper-product-pagination",
                    clickable: true,
                    renderBullet: function (index: number, className: string) {
                        return '<span class="' + className + '">0' + (index + 1) + "</span>";
                    },
                },
            });
          }
        }else{
          if ( swiper !== undefined ) {
            swiper.destroy( true, true );
            swiper = undefined;
          }
        }
    }

    initSwiper();    

    window.addEventListener("resize", (e) => {
      initSwiper();    
    });

};

export default initSwiperProductGallery;