import Swiper from 'swiper';
import { EffectFade, Navigation, Pagination, Autoplay  } from 'swiper/modules';

const initTestimonialsslider = () => {
    new Swiper('.swiper-testimonials', {        
        modules: [EffectFade, Navigation, Pagination, Autoplay ],
        spaceBetween: 30,
        //effect: "fade",
        //fadeEffect: { crossFade: true },
        centeredSlides: true,
        loop: true,
        speed:2000,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
    });
};

export default initTestimonialsslider;