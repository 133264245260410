const initHeaderAnimations = () => {
    const header = document.querySelector('.header-top');

    function editHeaderFormat(header : any): void {    
        if (window.scrollY > header.offsetHeight-10) {
            header.classList.add("header-top-fixed");
        } else if(window.scrollY < header.offsetHeight-20) {
            header.classList.remove("header-top-fixed");
        }
    }

    window.addEventListener("scroll", (e) => {
        editHeaderFormat(header);
    });
      
};

export default initHeaderAnimations;