import Swiper from 'swiper';
import { Navigation, Scrollbar } from 'swiper/modules';

const initSpecialproductsslider = () => {
  let swiper:any; 
  const specialproducts = document.querySelector('.swiper-specialproducts');
  
  function initSwiper(): void {    
    if (window.innerWidth > 992) {
      if ( swiper === undefined ) {
        swiper = new Swiper('.swiper-specialproducts', {
            modules: [Navigation, Scrollbar],
            slidesPerView: 1,
            spaceBetween: 0,

            breakpoints: {
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1200: {
                  slidesPerView: 3.5,
                  spaceBetween: 30,
                },
                1400: {
                  slidesPerView: 3.5,
                  spaceBetween: 30,
                },
                1920: {
                  slidesPerView: 4.5,
                  spaceBetween: 30,
                },
            },
            scrollbar: {
              el: ".swiper-scrollbar",
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            on: {
              slideChange: function () {
                if(specialproducts && specialproducts !== undefined){
                  if(swiper.activeIndex > 0){
                    specialproducts.classList.add("specialproducts-active-scroll")
                  }else{
                    specialproducts.classList.remove("specialproducts-active-scroll")
                  }
                }
              },
            },
        });
      }
    }else{
      if ( swiper !== undefined ) {
        swiper.destroy( true, true );
        swiper = undefined;
      }
    }
  }

  if(specialproducts){
    window.addEventListener("resize", (e) => {
      initSwiper();   
    });
    initSwiper();           
  }
};

export default initSpecialproductsslider;