import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const initImageslistAnimations = () => {
    
    const backtop = document.querySelector('.imageslist1-backtop');
    const imageslist = document.querySelectorAll(".swiper-imageslist");
    let swipers:any[] = [];

    function initSwiper(element : any): void {       
        let swiper_id = element.getAttribute('data-swiper'); 
        if (window.innerWidth <= 992) {
            if ( swipers[swiper_id] === undefined ) {
                swipers[swiper_id] = new Swiper('.swiper-'+swiper_id, {
                    modules: [Navigation],
                    slidesPerView: 1,
                    spaceBetween: 0,
                    loop: true,

                    breakpoints: {
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                    },
                    navigation: {
                        nextEl: ".swiper-button-next-"+swiper_id,
                        prevEl: ".swiper-button-prev-"+swiper_id,
                    },
                });
            }
        }else{
            if ( swipers[swiper_id] !== undefined ) {
                swipers[swiper_id].destroy( true, true );
                swipers.splice(swiper_id, 1);
            }
        }
    }
    
    imageslist.forEach((element) => {
        initSwiper(element);     
    });

    window.addEventListener("resize", (e) => {
        imageslist.forEach((element) => {
            initSwiper(element);    
        });
    });

    
    if ( backtop && backtop !== undefined ) {
        backtop.addEventListener("click", (e) => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        });
    }

};

export default initImageslistAnimations;