import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

const initVideosslider = () => {
    const swiper = new Swiper('.swiper-videos', {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        autoplay: {
            delay: 10000,
            disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: function (index: number, className: string) {
                return '<span class="' + className + '">0' + (index + 1) + "</span>";
            },
        },
        on: {
          init: function () {
            const slide_active = document.querySelector(".videosslider-container .swiper-slide-active"); 
            if(slide_active && slide_active !== null ){
                document.body.classList.remove('header-dark');
                document.body.classList.remove('header-light');
                document.body.classList.add('header-'+slide_active.getAttribute('data-theme'));
            }     
          },
        },
    });
    swiper.on('slideChange', function () {
        const slide_active = document.querySelector(".videosslider-container .swiper-slide-active"); 
        if(slide_active && slide_active !== null ){
            document.body.classList.remove('header-dark');
            document.body.classList.remove('header-light');
            document.body.classList.add('header-'+slide_active.getAttribute('data-theme'));
        }        
    }); 
};

export default initVideosslider;