const initHpAnimations = () => {
    const hp_animation_1 = document.querySelector('.hp-animation-1');
    const hp_animation_2 = document.querySelector('.hp-animation-2');
      
    function hpAnimation1 (element : any): void {    
        if (window.scrollY > element.offsetHeight-10) {
            element.querySelector('h1').classList.add("show");
        }
    }
    
    function hpAnimation2 (element : any): void {    
        if (window.scrollY > element.offsetHeight-10) {
            let text_top = element.querySelectorAll('.text-top>span');
            let text_bottom = element.querySelectorAll('.text-bottom>span');
            let time = 1;
            text_top.forEach((span: any) => {
                setTimeout(function() {                
                    span.classList.add('show');
                }, (time * 1000));
                time ++;
            });
            time += 1;
            text_bottom.forEach((span: any) => {
                setTimeout(function() {         
                    span.classList.add('show');
                }, (time * 1000));
                time ++;
            });
        }
    }

    window.addEventListener("scroll", (e) => {
        if(hp_animation_1){
            hpAnimation1(hp_animation_1);
        }
        if(hp_animation_2){
            hpAnimation2(hp_animation_2);
        }
    });
};

export default initHpAnimations;