



const initTutorials = () => {
    const videosPlay = document.querySelectorAll(".video-play-js");

    // Function to request full screen
    function launchFullscreen(element : any) {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { // Firefox
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { // Chrome, Safari, and Opera
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { // IE/Edge
            element.msRequestFullscreen();
        }
    }

    if ( videosPlay && videosPlay !== undefined ) {
        
        videosPlay.forEach((videoPlay) => {
            videoPlay.addEventListener('click', () => {
                const dataSrc:any = videoPlay ? videoPlay.getAttribute('data-video') : '';
    
                const video = document.createElement('video');
                if ( video && video !== undefined ) {
                    console.log(dataSrc);
                    video.src = dataSrc;
                    video.controls = true; 
                    video.autoplay = true;
                    
                    // Append the video element to the body
                    document.body.appendChild(video);
    
                    // Request full screen for the video
                    launchFullscreen(video);

                    
                    video.addEventListener('ended', () => {
                        document.body.removeChild(video);
                    });
                    document.addEventListener('fullscreenchange', () => {
                        if (!document.fullscreenElement) {
                            document.body.removeChild(video);
                        }
                    });
                }
            });
        });

    }
};

export default initTutorials;